<template>
  <div>
    <div class="table-header">
      <div class="theader-left">
        <span>出让用地列表</span>
      </div>
      <div class="theader-right">
        <div style="display: inline-block">
          <el-input
            v-model.trim="inputValue"
            placeholder="审批文号、地块号、申请主体、用地性质"
          />
        </div>
        <el-button size="small" @click="handleQuery">查询</el-button>
        <el-button size="small" @click="handleReset" style="margin-left: 0px"
          >重置</el-button
        >
        
        <el-upload
          class="avatar-uploader"
          action="https://www.mocky.io/v2/5185415ba171ea3a00704eed/posts/"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          accept=".zip"
          :before-upload="beforeAvatarUpload"
        >
          <el-button size="small" type="primary" style="margin:0 6px 0 6px">导入shape</el-button>
        </el-upload>
        <el-button size="small" type="primary">批量导入</el-button>
      </div>
    </div>
    <PublicTable ref="publicTable"></PublicTable>
  </div>
</template>

<script>
import table from "@/static/table.json";
//导入表格标题
import LoadData from "@/static/GetFileds/LandList.json";
import PublicTable from "@/components/table/PublicTable.vue";
import {ElMessage } from "element-plus";
export default {
  components: { PublicTable },
  data() {
    return {
      inputValue: "", //搜索的内容
      options: [], //选项
      value: "", //当前选项
      url: "", //传给子组件的url
    };
  },
  methods: {
    // 上传成功
    handleAvatarSuccess(res, file) {
      console.log(res, file);
       ElMessage({
            type: "success",
            message: "文件上传成功！",
          });
    },
     beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024  < 2

      if (!isLt2M) {
        this.$message.error('文件大小不能超过2kB!')
      }
      return isLt2M
    },
    //搜索
    handleQuery() {
      this.$refs.publicTable.getDataList(this.url, this.inputValue);
    },
    //重置
    handleReset() {
      this.inputValue = "";
      this.$refs.publicTable.getDataList(this.url, this.inputValue);
    },
  },
  mounted() {
    this.url = "/api/user/list";
    this.$refs.publicTable.getLoadData(LoadData, this.url, false);
    console.log("LoadData:", LoadData);
    // this.getDataList();
    this.options = table.options;
    this.value = this.options[0].value;

    // console.log(table.table);
  },
};
</script>

<style scoped>
.table-header {
  padding: 10px;
  overflow: hidden;
  /* border: 1px solid red; */
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid ; */
}
.table-header .el-select {
  width: 100px;
  margin: 0 10px 0 10px;
}
.theader-right :deep(.el-input input) {
  height: 32px;
  width: 300px;
  margin-right: 3px;
  border-radius: 0px;
}
/* upload */
.avatar-uploader{
  display: inline-block;
}
</style>